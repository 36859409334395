import { FC } from "react";
import { paths } from "../../constants";
import { addProjectImage } from "../../assets/shared";
import speakerphone from "../../assets/shared/voice.png";
import ProjectCard from "../../components/projects/ProjectCard";
import { ProjectImages } from "../../interfaces";

const withExtraComponent = (WrappedComponent: FC, styles: string) => {
  return (props: any) => {
    const extraData = {
      id: "newProject",
      linkTo: paths.newProject,
      images: {
        map_card: {
          de: addProjectImage,
          en: addProjectImage,
        } as ProjectImages['map_card'],
        tag_image: undefined,
      } as ProjectImages,
	  icon: speakerphone,
      name: {
        en: "Start your own project on Parry",
        de: "Starte dein eigenes Progekt auf Parry",
      },
      title: {
        de: "Starte dein Projekt!",
        en: "Start a new project!",
      },
      description: {
        en: "If you have a good idea to help your community, you can use Parry to involve all your members in mapping, organising and ranking priorities to establish where change is needed most.",
        de: "Du hast eine gute Idee und willst, dass sie so vielen Menschen wie möglich zugutekommt? Beziehe deine Mitglieder und Bürgerinnen und Bürger ganz einfach in die Planung mit ein — und finde mit unserer Karte heraus, wo der Bedarf am größten ist.",
      },
      reactions: false,
    };

    return (
      <div className={styles}>
        <WrappedComponent {...props} />
        <ProjectCard
          id={extraData.id}
          key={extraData.id}
          linkTo={extraData.linkTo}
          images={extraData.images} // Replace with the desired image path
		      icon={extraData.icon}
          title={extraData.name}
          description={extraData.description}
          location="null"
          reactions={extraData.reactions}
        />
      </div>
    );
  };
};

export default withExtraComponent;
