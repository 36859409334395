import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextSection from "../../shared/TextSection";
import Reactions from "./Reactions";
import CardWrapper from "./CardWrapper";
import { paths } from "../../constants";
import { ProjectText, ProjectImages } from "../../interfaces";
import ParryCard from "../../assets/shared/parrycard.png";

interface Props {
	id: string;
	linkTo: string;
	images: ProjectImages;
	icon: string;
	title: ProjectText | string;
	description: ProjectText;
	location: string;
	reactions: boolean;
}

const ProjectCard = ({
	linkTo,
	images,
	icon,
	title,
	description,
	location,
	reactions,
	id,
}: Props) => {
	const [currLang, setCurrLang] = useState<"de" | "en">("de");

	const lang = localStorage.getItem("lang");

	useEffect(() => {
		if (lang) {
			setCurrLang(lang as "de" | "en");
		} else {
			setCurrLang("de");
		}
	}, [currLang, lang]);

	return (
		<>
			<CardWrapper
				linkTo={linkTo === paths.newProject ? linkTo : paths.project(linkTo)}
				id={id}
			>
				<div className={`h-full w-full flex flex-col justify-start`}>
					<div className="mb-4">
						<img src={images.map_card[currLang]} alt="project-logo" className="object-cover h-[191px]" />
						{linkTo !== paths.newProject && (
							<div className="absolute top-[8px] right-[8px] py-[8px] px-[12px] bg-white rounded-[40px] flex items-center gap-[2px]">
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M13.1777 3.78457C13.1777 2.38936 11.6081 1.53549 10.3912 2.26866L8.84344 3.20111L5.46486 2.03799C5.29113 1.97818 5.09899 1.99831 4.94224 2.09274L1.86676 3.9456C1.32799 4.27019 1 4.84381 1 5.4615V10.2154C1 11.6106 2.5696 12.4645 3.78656 11.7313L5.3343 10.7989L8.71288 11.962C8.88661 12.0218 9.07876 12.0017 9.2355 11.9073L12.311 10.0544C12.8498 9.72981 13.1777 9.15619 13.1777 8.5385V3.78457ZM5.8711 3.43748L8.30664 4.27595V10.5625L5.8711 9.72405V3.43748ZM11.671 9.0438L9.52442 10.3371V4.18699L11.0311 3.27926C11.4368 3.03487 11.96 3.3195 11.96 3.78457V8.5385C11.96 8.7444 11.8506 8.93561 11.671 9.0438ZM4.65332 3.66294V9.81302L3.14663 10.7207C2.74097 10.9651 2.21777 10.6805 2.21777 10.2154V5.4615C2.21777 5.2556 2.3271 5.06439 2.5067 4.9562L4.65332 3.66294Z"
										fill="#16181C"
									/>
								</svg>
								<span>{location}</span>
							</div>
						)}
					</div>
					<div className="px-4 mb-[14px] h-full">
						
							<div className="flex items-center gap-[10px] mb-4">
								<img src={icon} alt="parry icon" className={linkTo == paths.newProject ? "w-[27px] object-cover object-right h-[32px]" : "h-[32px]"} />
								<span className="font-poppinsBold text-[14px]">Parry</span>
							</div>
						

						<TextSection
							title={typeof title === "string" ? title : title[currLang]}
						>
							{typeof description === "string"
								? description
								: description[currLang]}
						</TextSection>
						{/* <div className="mb-4"></div>
						{reactions && <Reactions />} */}
					</div>
				</div>
			</CardWrapper>
		</>
	);
};

export default ProjectCard;
